<template>
    <div class="card">
        <div class="card-body">
            <h6>
                <span v-text="$t('general.domains')"></span>
                <button v-b-popover.hover.top="$t('messages.ownDomain')" class="btn p-0 ml-1">
                    <i class=" fa fa-question-circle fa-sm text-info"></i>
                </button>
            </h6>
            <v-observer class="form-rounded-inputs" tag="form" ref="formStoreDomain" v-slot="{reset}"
                @submit.prevent="fnValidateDomain()">
                <div class="row">
                    <div class="col">
                        <v-validation
                            :rules="{required: true , regex:/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/}"
                            v-slot="{ errors }" :name=" $t('general.domain') ">
                            <div class="form-group">
                                <div class="input-group">
                                    <input type="text" class="form-control" v-model="newDomain.domain"
                                        placeholder="mystorename.com" :class=" { 'is-invalid':errors.length > 0 } ">
                                    <div class="input-group-append">
                                        <button class="btn btn-success" :disabled="loading.creatingDomain">
                                            <i class="fa fa-plus-circle" v-if=" !loading.creatingDomain "></i>
                                            <i class="fa fa-spinner fa-spin " v-else></i>
                                        </button>
                                    </div>
                                    <div class="invalid-feedback" v-if="errors.length > 0">
                                        {{ errors[0] }}
                                    </div>
                                </div>
                            </div>
                        </v-validation>
                    </div>
                    <div class="col-auto pl-0">
                        <div class="form-group">
                            <button class="btn btn-outline-success circle-35" type="button"
                                @click="reset() ; fnApiRefresStoreDomains()">
                                <i class="fa fa-sync" v-if=" !loading.refresDomains "></i>
                                <i class="fa fa-spinner fa-spin " v-else></i>
                            </button>
                        </div>
                    </div>
                </div>
                <input type="submit" hidden>
            </v-observer>
            <table class="table ecart-table table-striped animated fadeIn" v-show="storeDomains.length > 0">
                <thead>
                    <tr>
                        <th class="" v-text="$t('general.domain')"></th>
                        <th class="text-center">
                            <span v-text=" 'CNAME' "></span>
                        </th>
                        <th class="text-center" v-text="$t('tables.status')"></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for=" (domain,index) in storeDomains" :key="domain.id">
                        <td v-text="domain.domain">
                        </td>
                        <td class=" text-center">
                            <span v-text="domain.cname"></span>
                             <button v-b-popover.hover.top="$t('messages.cname')" class="btn p-0 ml-1">
                                <i class=" fa fa-question-circle fa-sm text-info"></i>
                            </button>
                        </td>
                        <td class="text-center" :class="domain.status == 'failing' ? 'text-danger' : '' ">
                            <p v-text="domain.status" v-if="domain.status">
                            </p>
                            <button class="btn p-0 " type="button" @click="fnApiRefresStoreDomains()" v-else>
                                <span class="text-success" v-text="$t('general.refresh')"
                                    v-if=" !loading.refresDomains "></span>
                                <i class="fa fa-spinner fa-spin " v-else></i>
                            </button>
                        </td>
                        <td>
                            <button class="btn btn-danger circle-25 mx-auto"
                                :disabled="(loading.deletingDomain !== null) && (loading.deletingDomain == index) "
                                @click="fnApiDeleteStoreDomain(index)">
                                <i class="fa fa-spinner fa-spin fa-sm "
                                    v-if=" (loading.deletingDomain !== null) && (loading.deletingDomain == index) "></i>
                                <i class="fa fa-trash fa-sm" v-else></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="bg-light p-5" v-show="storeDomains.length == 0">
                <p class="bg-light text-center" v-text="$t('messages.ownDomain')"></p>

            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapActions,
        mapState
    } from 'vuex'
    export default {
        // data() {
        //     return { }
        // },
        computed: {
            ...mapState('EcartStore', ['storeDomains', 'loading', 'newDomain'])
        },
        methods: {
            ...mapActions('EcartStore', ['fnApiCreateStoreDomain', 'fnApiDeleteStoreDomain',
                'fnApiRefresStoreDomains'
            ]),
            async fnValidateDomain() {
                if (await this.$refs['formStoreDomain'].validate()) {
                    await this.fnApiCreateStoreDomain();
                    this.$refs['formStoreDomain'].reset()
                }
            }
        },
        mounted() {}
    }
</script>
<style>
</style>